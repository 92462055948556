import React from "react"
import { Row, Col, Button } from "reactstrap"

import Heading from "src/components/index/Heading"

import workflowIclLetterFlypast from "src/assets/img/screenshots/automation-workflow.png"
import exampleICL from "src/assets/pdf/example-icl.pdf"

const AutomationWorkflowSection = () => (
  <section>
    <Row className="u-centerFlex">
      <Col lg={5} md={12} className="SplitContentRow-graphic">
        <img
          alt="Carebit - generate the Initial Consultation Letter automatically"
          src={workflowIclLetterFlypast}
          className="u-outline"
          style={{ width: "100%" }}
        />
      </Col>
      <Col
        lg={{ size: 6, offset: 1 }}
        md={{ size: 12 }}
        className="SplitContentRow-text"
      >
        <Row>
          <Heading title="Automate patient correspondence">
            Generate letters and emails automatically
          </Heading>
        </Row>
        <p className="u-midGrey">
          Creating letters, especially templated ones with the same information
          on, quickly becomes repetitive for secretaries. Carebit allows you to
          create your own email and letter templates and then set them up so
          that when the rules are triggered (e.g. by a booking or invoice being
          created), they are sent to the patient.
        </p>

        <p className="u-midGrey">
          For example, Santis generates an Initial Consultation Letter for all
          patients{" "}
          <span className="u-fontBold">
            making their first booking with them
          </span>
          , informing them of their fees and rights. The secretary doesn’t have
          to remember to generate, send and store the letter as it all happens
          within seconds of the booking being made, silently in the background.
        </p>

        <div style={{ marginTop: "40px" }}>
          <a href={exampleICL} rel="noopener noreferrer" target="_blank">
            <Button color="secondary" size="md">
              View example letter &rarr;
            </Button>
          </a>
        </div>
      </Col>
    </Row>
  </section>
)

export default AutomationWorkflowSection
