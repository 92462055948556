import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Badge, Button } from "reactstrap"

import GenericAutomationChartContainer from "src/containers/charts/GenericAutomationChartContainer"

import Layout from "src/components/Layout"
import Seo from "src/components/Seo"
import Heading from "src/components/index/Heading"
import ViewMoreFeatures from "src/components/features/ViewMoreFeatures"

import TestimonialCard from "src/components/TestimonialCard"
import AutomationWorkflowSection from "src/components/features/automation/AutomationWorkflowSection"
import AutomationPayInvoiceOnline from "src/components/features/automation/AutomationPayInvoiceOnline"

import secretaryAvatar from "src/assets/img/testimonials/secretary-small-square.jpg"
import automaticCancellationEmail from "src/assets/img/screenshots/revenue-enhancement-automatic-cancellation-email.png"
import automationInvoiceEmail from "src/assets/img/screenshots/automation-invoice-email.png"

import exampleInvoice from "src/assets/pdf/example-invoice.pdf"

const FeaturePracticeManagement = () => {
  return (
    <Layout>
      <Seo
        title="Carebit: Features | Automation"
        pathname="/features/automation"
        description="Automating administrative tasks such as generating letters and emails allows you and your staff to focus on better patient care. You save money, get paid earlier and your secretaries are freed up to help patients."
      />
      <div className="u-centerFlex u-textCenter Hero">
        <Container>
          <Row>
            <Col
              lg={{ size: 6, offset: 3 }}
              md={{ size: 10, offset: 1 }}
              style={{ zIndex: 2, padding: "120px 40px 200px", color: "white" }}
            >
              <Badge color="info" className="u-marginBottom20">
                Features
              </Badge>
              <h1>Automation</h1>
              <p className="u-whiteTranslucent">
                Automating administrative tasks such as generating letters and
                emails with Carebit allows you and your staff to focus on better
                patient care. You save money, get paid earlier and your
                secretary’s time is freed up to help patients. Carebit is a
                revolution in practice management.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <Container>
          <Row>
            <Col md={{ size: 10, offset: 1 }}>
              <section>
                <Row className="u-centerFlex SplitContentRow">
                  <Col lg={6} md={12} className="SplitContentRow-text">
                    <Row>
                      <Heading title="Boost productivity">
                        Save hours of time and thousands of pounds
                      </Heading>
                    </Row>
                    <p className="u-midGrey">
                      Carebit can take care of many of the time-consuming tasks
                      involved in running a clinic. For example, you might need
                      to send a letter out to a patient when their first booking
                      is created, or email patients quality of life
                      questionnaires to track their progress following an
                      operation. Carebit can handle all of this in a fraction of
                      the time, relieving the burden on your secretary.
                    </p>

                    <div style={{ marginTop: "40px" }}>
                      <Link to="/case-studies/santis-automating-secretarial-tasks">
                        <Button color="secondary" size="md">
                          Read the case study on Santis & automation &rarr;
                        </Button>
                      </Link>
                    </div>
                  </Col>
                  <Col lg={6} md={12} className="SplitContentRow-graphic">
                    <GenericAutomationChartContainer
                      chartData={[{ x: "Carebit", y: 8 }]}
                      countUpTextBefore=" "
                      countUpTextAfter=" hours saved a week by a typical practice"
                    />
                  </Col>
                </Row>
              </section>

              <AutomationWorkflowSection />

              <section>
                <Row className="u-centerFlex">
                  <Col lg={6} md={12} className="SplitContentRow-text">
                    <Row>
                      <Heading title="Maximising your income">
                        Automatic patient invoicing
                      </Heading>
                    </Row>
                    <p className="u-midGrey">
                      It’s easy for invoices to be missed, but Carebit can be
                      configured to generate and email PDF invoices to self-pay
                      patients as soon as a booking is made, complete with a
                      link to instantly pay online by credit or debit card.
                      Forgetting to invoice a patient is now a thing of the
                      past.
                    </p>

                    <div style={{ marginTop: "40px" }}>
                      <a
                        href={exampleInvoice}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Button color="secondary" size="md">
                          View example invoice &rarr;
                        </Button>
                      </a>
                    </div>
                  </Col>
                  <Col
                    lg={{ size: 5, offset: 1 }}
                    md={{ size: 12 }}
                    className="SplitContentRow-graphic"
                  >
                    <img
                      alt="Carebit - automate the sending of invoices"
                      src={automationInvoiceEmail}
                      className="u-outline"
                      style={{ width: "100%" }}
                    />
                  </Col>
                </Row>
              </section>

              <AutomationPayInvoiceOnline />

              <section>
                <Row className="u-centerFlex">
                  <Col lg={6} md={12} className="SplitContentRow-text">
                    <Row>
                      <Heading title="Protecting your income">
                        Ensure patients pay before the appointment
                      </Heading>
                    </Row>
                    <p className="u-midGrey">
                      While the vast majority of patients are very good about
                      paying on time, it can be extremely time-consuming to
                      chase those who pay weeks or months after the appointment
                      - and even then you may never be paid as many doctors are
                      reluctant to pursue unpaid invoices. Carebit can send
                      reminders on a schedule of your choosing, but can also
                      monitor and automatically cancel appointments on the day
                      if the invoice has not been paid, protecting your income.
                    </p>

                    <div style={{ marginTop: "40px" }}>
                      <Link to="/features/revenue-enhancement">
                        <Button color="secondary" size="md">
                          Learn more about revenue enhancement &rarr;
                        </Button>
                      </Link>
                    </div>
                  </Col>
                  <Col
                    lg={{ size: 5, offset: 1 }}
                    md={{ size: 12 }}
                    className="SplitContentRow-graphic"
                  >
                    <img
                      alt="Carebit - automatically cancel unpaid invoices"
                      src={automaticCancellationEmail}
                      className="u-outline"
                      style={{ width: "100%" }}
                    />
                  </Col>
                </Row>
              </section>
            </Col>
          </Row>
        </Container>

        <div className="Index-gradient">
          <div className="ColouredDivider Index-dividerBlueGreen">
            <Container>
              <section>
                <TestimonialCard
                  author="Mrs Simone Castle"
                  authorSubtext="Secretary, Santis"
                  avatarSrc={secretaryAvatar}
                >
                  <p>
                    “Carebit is a user-friendly practice management platform
                    which is easy to learn and use. I rely on its many features
                    to run Santis, where it is critical to the smooth running of
                    the office. For example, the legally-required initial
                    consultation letter for patients is generated, appended with
                    the doctor’s signature, and emailed to the patient as soon
                    as an initial consultation is booked. If a patient is
                    self-pay, an invoice is created and emailed to the patient
                    directly prior to the consultation taking place and the
                    consultant can view an interactive, up-to-date clinic list
                    at any time.{" "}
                    <span className="u-fontBold">
                      All of this happens without my having to do anything
                    </span>
                    .
                  </p>

                  <p>
                    <span className="u-fontBold">
                      The time it has saved me is tremendous, leaving me to be
                      able to run a more efficient, reliable office
                    </span>
                    . I would strongly recommend Carebit to any clinic no matter
                    how big or small. The team at Carebit listen to and
                    understand how important the role I play in the company is,
                    and assist me by adding features to Carebit as I discover
                    the need for them.”
                  </p>
                </TestimonialCard>
              </section>
            </Container>
          </div>
        </div>

        <ViewMoreFeatures />
      </div>
    </Layout>
  )
}

export default FeaturePracticeManagement
